<template>
  <div>
    <!--  Dialog reçu-->
    <v-dialog
      v-model="show"
      max-width="500"
    >
      <v-card>
        <!--  Print-->
        <v-icon
          v-if="getDialogInfo.print"
          right
          dark
          color="primary"
          @click="print"
        >
          mdi-printer
        </v-icon>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="close"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <v-icon
                v-if="getDialogInfo.iconState"
                size="78px"
                :color="getDialogInfo.iconColor"
              >
                {{ getDialogInfo.iconName }}
              </v-icon>

              <div id="printMe">
                <!--  Si le texte est en html-->
                <template v-if="getDialogInfo.html">
                  <div
                    v-html="getDialogInfo.message"
                  />
                </template>
                <template v-else>
                  {{ getDialogInfo.message }}
                </template>
              </div>
            </v-col>
          </v-row>

          <v-btn
            class="mt-2 ml-4"
            color="info"
            depressed
            default
            rounded
            @click="close"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import Vue from 'vue'
  import VueHtmlToPaper from 'vue-html-to-paper'
  const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes',
    ],
    styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css',
    ],
  }

  Vue.use(VueHtmlToPaper, options)

  export default {
    data () {
      return {
        show: false,
      }
    },

    computed: {
      ...mapGetters('dialog', [
        'getDialogInfo',
      ]),
    },

    watch: {
      getDialogInfo: function (val) {
        this.show = val.show
      },
    },

    mounted () {
      this.show = this.getDialogInfo.show
    },

    methods: {
      ...mapMutations('dialog', [
        'hideDialog',
      ]),
      close () {
        // On ferme le dialog
        this.show = false
        // Vuex. On mets la variable 'show' à false dans le vuex
        this.hideDialog()
      },
      print () {
        // Pass the element id here
        this.$htmlToPaper('printMe')
      },
    },
  }
</script>
